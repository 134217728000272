@use "../../styles/colors";
@use "../../styles/vars";
table {
	margin: 30px 0;
	border-collapse: collapse;
	width: 100%;

	.i {
		font-size: 24px;
		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			font-size: 28px;
		}
	}

	th,
	td {
		padding: 5px 0px;
		width: 100px;
	}
	th {
		width: auto;
		// background: colors.$light;
		color: colors.$primary-200;
		font-size: 14px;
		@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
			font-size: 12px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			font-size: 16px;
		}
	}

	td {
		text-align: center;
		font-size: 14px;
		@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
			padding: 10px 0;
			font-size: 12px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			padding: 20px 0;
			font-size: 18px;
		}
	}
}

.green {
	color: green;
}
.red {
	color: tomato;
}
