@use "../../styles/colors";
@use "../../styles/vars";

.tomtom-nav {
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 90vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-right: 20px;
	text-transform: uppercase;
	background: rgba($color: #000000, $alpha: 0.92);
	transform: translateX(120%);
	transition: transform 0.5s ease-in;
	z-index: 9999;

	@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		position: absolute;
		top: 15vh;
		height: auto;
		width: 100vw;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		background: rgba($color: #000000, $alpha: 0.8);
		transform: translateX(0%);
	}
	a {
		text-decoration: none;
		color: colors.$light;
	}

	ul {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		padding-top: 50px;
		list-style: none;
		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			height: auto;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			padding-top: 0;
		}

		li {
			padding: 20px 0;
			transform: scale(1);

			a {
				padding: 5px 8px;
				text-decoration: none;
				font-size: 14px;
				letter-spacing: 2px;
				transition: color 0.3s, font-size 0.3s;
				color: colors.$light;
				z-index: 10000;

				&:hover {
					color: colors.$primary-300;
				}
				&.active {
					font-size: 20px;
				}
			}
		}
	}
	.btn-translate-wrapper {
		position: relative;
		.btn-translate {
			position: relative;
			padding: 10px 20px;
			border: 1px solid colors.$light;
			border-radius: 5px;
			font-size: 14px;
			text-transform: uppercase;
			color: colors.$light;
			background: none;
			transition: all 0.3s;
			z-index: 1;
			cursor: pointer;
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		
			}
			&:hover {
				color: colors.$primary-300;
				border: 1px solid colors.$primary-300;
				transform: scale(1.1);
			}
		}
		ul {
			position: absolute;
			top: -10%;
			left: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			border-radius: 5px;
			overflow: hidden;
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				top: 135%;
			}

			li {
				margin-bottom: 2px;
				padding: 0;
				width: 100%;

				button {
					padding: 10px;
					width: 100%;
					border: none;
					background-color: #4e4e4e8a;
					color: colors.$light;
					cursor: pointer;
					&:hover {
						background-color: #f3f3f342;
					}
				}
			}
			li:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.show-navigation {
	transform: translateX(0%);
}
.hide-navigation {
	transform: translateX(120%);

	@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		transform: translateX(0%);
	}
}
