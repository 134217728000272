@use "src/styles/colors";
@use "src/styles/vars";

.tomtom-section-contact {
	margin-top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: 710px;
	@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		margin-top: 70px;
	}
	@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
		height: 550px;
	}

	.frame {
		margin-top: 30px;
		position: relative;
		width: 90%;
		height: 250px;
		border: 10px solid black;
		
		.contact {
			position: relative;
			padding: 150px 0 50px 0;
			top: 20%;
			left: 55%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: left;
			width: 90%;
			min-height: 300px;
			transform: translateX(-50%);
			background: rgba($color: colors.$light, $alpha: 0.99);
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				position: relative;
				top: 20%;
				left: 60%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				width: 90%;
				min-height: 300px;
				transform: translateX(-50%);
				background: rgba($color: colors.$light, $alpha: 0.99);
			}
			@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
				padding: 20px 0 20px 0;
				position: relative;
				top: 20%;
				left: 60%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				width: 90%;
				min-height: 300px;
				transform: translateX(-50%);
				background: rgba($color: colors.$light, $alpha: 0.99);
			}
			.round {
				margin-right: 0;
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background: royalblue;
				@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
					margin-right: 10px;
				}
				.i {
					color: colors.$white-100;
				}
			}
			.p {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 20px 0;
				font-size: 16px;
				@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
					font-size: 20px;
				}
				@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
					display: flex;
					align-items: center;
					flex-direction: row;
					margin: 10px 0 10px 450px;
					font-size: 20px;
				}
				.small-text {
					font-size: 12px;
					@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
						font-size: 20px;
					}
				}
				a {
					text-decoration: none;
					color: black;
				}
				span {
					text-align: center;
				}
			}
			.contact-photo {
				position: absolute;
				top: 10%;
				left: -10%;
				width: 180px;
				height: 180px;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translateY(-50%);
				border-radius: 50%;
				background: rosybrown;
				@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
					width: 250px;
					height: 250px;
				}
				@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
					position: absolute;
					top: 10%;
					left: -10%;
					width: 350px;
					height: 350px;
					display: flex;
					align-items: center;
					justify-content: center;
					transform: translateY(-50%);
					border-radius: 50%;
					background: rosybrown;
				}
				@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
					position: absolute;

					left: -10%;
					width: 350px;
					height: 350px;
					display: flex;
					align-items: center;
					justify-content: center;
					transform: translateY(-50%);
					border-radius: 50%;
					background: rosybrown;
				}
				img {
					width: 90%;
					height: 90%;
					border-radius: 50%;
					object-fit: cover;
					object-position: -35px;
					@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
						width: 90%;
						height: 90%;
						border-radius: 50%;
						object-fit: cover;
						object-position: -60px;
					}
				}
			}
		}
	}
	h1 {
		padding: 32px;
	}
}
