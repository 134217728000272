@use "../../styles/colors";
@use "../../styles/vars";

.trust {
	position: relative;
	padding: 5px;
	width: 100%;
	height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url("../../images/business/business-m.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	z-index: 0;
	@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
		padding: 30px;
	}
	@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		padding: 20px 80px;
	}
	.trust-text {
		color: colors.$white-100;
		z-index: 1;
        
		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		}
	}
	.trust-tex-one {
        margin-bottom: 5px;
        padding-bottom: 5px;
        width: 230px;
		font-size: 14px;
		letter-spacing: 1.4px;
		text-align: center;
        border-bottom: 1px solid goldenrod;
        @media screen and (min-width: map-get(vars.$breakpoints, "small")) {
            width: 340px;
            font-size: 16px;
		}
        @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
            width: 480px;
            font-size: 20px;
            letter-spacing: 1.6px;
		}
        @media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
            margin-bottom: 10px;
            padding-bottom: 10px;
            width:640px;
            font-size: 24px;
            letter-spacing: 2px;
            border-bottom: 2px solid goldenrod;
		}
	}
	.trust-tex-two {
        font-size: 10px;
		text-align: center;
        letter-spacing: 1px;
        @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
            font-size: 12px;
            letter-spacing: 1.2px;
		}
	}
	.trust-hero {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(colors.$black, 0.4);
		z-index: -1;
	}
}
