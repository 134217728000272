@use "./styles/colors.scss";
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
body {
	width: 100vw;
	background-color: colors.$white;
	color: colors.$black;
	z-index: 0;
	font-family: "Lato", sans-serif;
	font-family: "Montserrat", sans-serif;

	h1,
	h2,
	h3 {
		font-family: "Cormorant Garamond", serif;
	}

	p,
	span, a{
		font-family: "Montserrat", sans-serif;
	}
	.header-logo {
		font-family: "Lato", sans-serif;
	}
}
.tomtom-hero-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color: #000000, $alpha: 0.6);
	z-index: 0;
}
