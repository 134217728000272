@use "src/styles/colors";
@use "src/styles/vars";

.header {
	position: relative;
	width: 100vw;
	height: 75vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(../../images/headerPicture/kopenhaga.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	
	.bg-shadow {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba($color: #000000, $alpha: 0.4);
		z-index: 0;
	}
	.text_wrapper {
		
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		color: whitesmoke;
		z-index: 1;

		h1 {
			position: absolute;
			top: 2vh;
			left: 50%;
			width: 90vw;
			transform: translateX(-50%);
			padding-right: auto;
			text-align: center;
			font-size: 18px;
			letter-spacing: 1.4px;
			color: colors.$white-100;
			// border: 1px solid red;
			@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
				// width: auto;
				font-size: 28px;
			}
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				top: 8vh;
				left: 70%;
				width: 65vw;
				padding-right: 5vw;
				font-size: 42px;
			}
		}

		button {
			padding: 8px 15px;
			font-size: 10px;
			letter-spacing: 1.2px;
			@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
				padding: 15px 25px;
				font-size: 16px;
				letter-spacing: 1.4px;
			}
		}
		.header-contact {
			margin-bottom: 0;
			width: 100vw;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
				align-items: flex-start;
				margin-bottom: 20%;
			}
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				margin-bottom: 40px;
			}
			@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
				margin-bottom: 50px;
			}

			.round {
				margin-bottom: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
					width: 60px;
					height: 60px;
				}
				@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
					width: 80px;
					height: 80px;
				}
				.i {
					font-size: 20px;
					@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
						font-size: 28px;
					}
					@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
						font-size: 32px;
					}
				}
			}
			.phone {
				background: green;
			}
			.email {
				background: royalblue;
			}
			.header-contact-media {
				width: 100%;
				padding: 10px 10px 10px 10%;
				@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
					background: none;
					width: auto;
				}

				a {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					text-decoration: none;
					color: colors.$white-100;
					@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
						flex-direction: row;
					}
					span {
						padding: 0 0;
						font-size: 16px;
						padding: 0px 0;
						background: none;
						font-weight: bold;
						@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
							padding: 10px 20px;
							font-size: 22px;
						}
					}
				}
			}
		}
	}
}

// .header {
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: flex-end;
// 	width: 100vw;
// 	height: 50vh;
// 	overflow: hidden;
// 	transition: all 0.3s ease-in;

// 	.tomtom-header-text {
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		justify-content: center;
// 		width: 60%;
// 		height: 100%;
// 		background: #000;
// 		text-align: center;
// 		color: whitesmoke;
// 		z-index: 2;

// 		h1 {
//             font-size: 16px;
//             font-weight: bold;
//             letter-spacing: 2px;
// 			transition: all 0.3s .3s ease-in;

//             @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {

// 			}
// 		}
// 		p {
//             font-size: 12px;
//             letter-spacing: 1.4px;
// 			transition: all 0.3s ease-in;
//             z-index: 9;
//             @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {

// 			}
// 		}

// 		button {
// 			margin-top: 30px;
//             padding: 10px 20px;
//             font-size: 10px;
// 			transition: all 0.5s 1.4s ease-in;

//             @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
//                 margin-top: 100px;
// 			}
// 		}
// 	}

// 	.heroImg  {
// 		position: relative;
// 		background-color: red;
// 		width: 200px;
// 		height: 200px;
// 	}
// 	.header-img {
// 		position: relative;
// 		width: 40%;
// 		height: 100%;
// 		background: colors.$grey;

// 		img {
// 			position: absolute;
// 			top: 50%;
// 			right: 10%;
// 			height: 30vh;
// 			width: 40vw;
// 			filter: grayscale(100%);
// 			object-fit: cover;
// 			object-position: center;
// 			transition: all 0.5s .9s ease-in;
// 			z-index: 999;
//             @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
//                 right: 30%;
//                 width: 35vw;
// 			}
// 		}
// 	}
// }
// .hide-header h1,
// .hide-header p {
// 	transform: translateY(-20vh);
// }
// .show-header h1 {
// 	transform: translateY(0);
// }
// .hide-btn button {
// 	transform: translateY(1500%);
// }
// .show-btn button {
// 	transform: translateY(0);
// }

// .hide-header-img {
// 	transform: translateY(-50%) rotate(0deg) translateX(150%);
// }

// .show-header-img {
// 	transform: translateY(-50%) rotate(-5deg) translateX(0);
// }
