@use "../../styles/colors";
@use "../../styles/vars";

	
   
    .tomtom-section-our-cars {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
          margin-top: 70px;
        }
        h1 {
            color: colors.$dark-gold;
            padding: 30px;
            font-size: 32px;
        }
        ul{
            display: flex;
            flex-direction: column;
            align-items: center;
            }
          
    }
   


