@use "src/styles/colors";
@use "src/styles/vars";

.accordion-list {
	padding: 10px 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;


	li {
		width: 100%;
		list-style: none;
		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			width: 100%;
		}

		.accordion-title {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			margin: 5px 0;
			padding: 20px 0;
			border-radius: 0;
			border: none;
			background-color: colors.$light;
			background: none;
			transition: all 0.3s ease-in-out;
			cursor: pointer;
			z-index: 10;
			overflow: hidden;
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				border-radius: 5px;
			}


			// .accordion-title-bg{
			// 	position: absolute;
			// 	top: 50%;
			// 	left: 50%;
			// 	width: 100%;
			// 	height: 30%;
			// 	transform: translate(-50%, -50%);
			// 	background: linear-gradient(39deg, rgba(124,131,131,0.7399334733893557) 0%, rgba(178,182,174,1) 52%, rgba(223,226,226,1) 100%);
			// 	z-index: 0;
			// 	@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			// 		height: 42%;
			// 	}
			// }

			.accordion-title-img {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 25%;
				height: 100%;
				z-index: 1;

				@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
					width: 15%;
				}

				i {
					color: colors.$grey;
					font-size: 40px;
					@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
						font-size: 60px;
					}
				}
			}
			h2 {
				width: 70%;
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 22px;
				letter-spacing: 2px;
				color: colors.$primary-200;
				z-index: 1;

				@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
					font-size: 40px;
				}
			}
			.arrow-wrapper {
				width: 20%;
				height: 80%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 5px;
				z-index: 1;
				@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
					width: 14%;
				}

				.arrow-up {
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-bottom: 15px solid colors.$light-200;
					transition: all 0.5s ease-out;

					@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
						border-left: 15px solid transparent;
					border-right: 15px solid transparent;
					border-bottom: 20px solid colors.$light-200;
					}
				}

				.arrow-down {
					transform: rotate(-180deg);
				}
			}
		}
		.accordion-description {
			position: static;
			max-height: 0;
			padding: 0 0;
			margin: 0 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			// background-color: colors.$light;
			text-align: left;
			font-size: 12px;
			line-height: 28px;
			transition: all 0.3s ease-in-out;

			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				font-size: 16px;
			}
		}

		.accordion-description-show {
			max-height: 9999px;
			margin: 5px 0;
			padding: 15px 25px;
			opacity: 1;
			transform: translateY(0), scaleY(1);

			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				padding: 40px 60px;
			}
		}
		.accordion-description-hidden {
			max-height: 0;
			opacity: 0;
			transform: translateY(-100%), scaleY(0);
		}
	}
}
