$dark: #222831;
$grey: #393e46; 
$grey-0: #797979; 
$dark-gold: #b55400; 
$light: #eeeeee;
$light-100: #dedede;
$light-200: #9f9f9f;
$white: #FFFFFF;
$white-100: #fefefe;

$primary-100: #395e95; 
$primary-200: #4f7fc8; 
$primary-300: #5fafff;

$black: #000000;
