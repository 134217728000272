@use "src/styles/colors";
@use "src/styles/vars";

.about-us-video-wrapper {
	position: relative;
	margin: 30px 0;
	width: 100%;
	height: 80vh;
	@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
		height: auto;
	}
	@media screen and (min-width: map-get(vars.$breakpoints, "medium"))  {
		height: 60vh;
	}

	.about-us-video {
		width: 100%;
		height: 100%;
		background-color: rgb(146, 103, 103);
		object-fit: cover;
	}

	.about-us-video-text-section {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 2.5%;
		padding: 40px 20px;
		width: 95%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background: rgba($color: #2f2f2f, $alpha: 0.7);
		color: colors.$white-100;
		h2 {
			padding: 5px 0 5px 15px;
			border-left: 1.4px solid colors.$white-100;
			font-size: 16px;
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				padding: 14px 0 15px 30px;
				font-size: 32px;
				border-left: 2px solid colors.$white-100;
			}
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			width: 60%;
			left: 50px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
			width: 45%;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "large")) {
			width: 40%;
		}
		.about-us-video-text-wrapper {
			width: 100%;
			height: 100%;
			overflow: scroll;

			p {
				padding: 20px 0;
				line-height: 18px;
				letter-spacing: 1.2px;
				font-size: 14px;
				@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
					
					
					line-height: 24px;
					letter-spacing: 1.4px;
					font-size: 18px;
				}
			}
		}
	}
}
