@use "src/styles/colors";
@use "src/styles/vars";

.why-work-with-us {
	position: relative;
	padding: 60px 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: colors.$white-100;
	background-image: url("../../images/why-us/whyUs.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 1;

	@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
		padding: 60px 60px;
	}

	.why-work-with-us-title {
		width: 100%;
		h2 {
			margin-left: 30px;
			margin-bottom: 60px;
			padding: 5px 0 5px 10px;
			border-left: 1.4px solid colors.$white-100;
			font-size: 16px;
			letter-spacing: 0.3px;
			@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
				margin-left: 60px;
				padding: 10px 0 10px 20px;
				border-left: 2px solid colors.$white-100;
				font-size: 32px;
				letter-spacing: 2px;
			}
		}
	}
	.why-work-with-us-reasons {
		margin: auto 0;
		padding: 0;
		width: 100%;
		display: grid;
		grid-template-columns: auto;
		gap: 15px;
		justify-content: center;
		align-items: center;

		@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
			padding: 50px 10%;
			grid-template-columns: auto auto;
			gap: 10px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
			margin: 40px 50px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "large")) {
			padding: 120px 10%;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "xl")) {
			padding: 180px 10%;
		}
	}

	.why-work-with-us-hero-img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba($color: #000000, $alpha: 0.55);
		z-index: -1;
	}
}

.why-work-with-us-reasons-item {
	display: flex;
	z-index: 1;
	width: 85vw;

	@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		width: 40vw;
	}

	.why-work-with-us-reasons-item-value {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 0;
		font-size: 30px;
		width: 40%;
		color: rgb(255, 140, 0);
		@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
			font-size: 50px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "large")) {
			font-size: 80px;
		}
	}
	.why-work-with-us-reasons-item-text {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 5px;
		width: 60%;
		font-size: 13px;
		letter-spacing: 1.5px;

		@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
			padding: 8px;
			font-size: 16px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
			padding: 10px;
			font-size: 20px;
		}
		@media screen and (min-width: map-get(vars.$breakpoints, "large")) {
			padding: 14px;
			font-size: 20px;
		}
	}
}
