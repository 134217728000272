@use 'src/styles/_colors.scss';
.tomtom-button-one {
	border-radius: 5px;
	border: none;
	padding: 15px 30px;
	background: colors.$primary-100;
    color: colors.$white-100;
	text-transform: uppercase;
    cursor: pointer;
    transition: background .3s ;

	&:hover {
		background: colors.$primary-300;
	}
}
