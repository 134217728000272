@use "../../styles/colors";
@use "../../styles/vars";
footer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	background-image: url("../../images/kobenhavn/kobenhavn_Xs.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	color: colors.$light;
	z-index: 1;
	@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
		background-image: url("../../images/kobenhavn/kobenhavn_M.jpg");
	}
	@media screen and (min-width: map-get(vars.$breakpoints, "large")) {
		background-image: url("../../images/kobenhavn/kobenhavn_L.jpg");
	}

	a {
		text-decoration: none;
		color: colors.$primary-300;
	}

	.tomtom-footer-sections {
		display: flex;
		justify-content: center;
		width: 100%;
		z-index: 0;
		.tomtom-footer-contact,
		.tomtom-footer-navigation {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			margin-top: 20px;
			padding: 20px 20px;
			width: 100%;
			line-height: 30px;
			font-size: 12px;
			z-index: 2;
			
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				width: 40%;
				padding: 20px 40px;
				font-size: 14px;
			}
		}
		.tomtom-footer-hero {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba($color: #000000, $alpha: 0.9);
			z-index:-1;
		}
	}
	.tomtom-footer-info {
		margin-top: 20px;
		padding: 20px;
		width: 80%;
		border-top: 1px solid colors.$grey;
		z-index: 1000;
		.tomtom-footer-info-paragraph {
			display: inline;
			padding: 4px 8px;
			color: colors.$white;
			background: #000;
		}
	}
}

.footer-hero-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color: #000000, $alpha: 0.1);
	z-index: -1;
}
