@use "src/styles/colors";
@use "src/styles/vars";

.tomtom-cart-side-photo {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 50px 0;
	border-radius: 5px;
	overflow: hidden;
	@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
		width: 90%;
		flex-direction: row;
	}

	.tomtom-cart-side-photo-img {
		width: 100%;
		@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
			width: 45%;
		}
		img {
			width: 100%;
			height: auto;
			object-fit: cover;
			object-position:center;
	
			@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
				height: 100%;
				width: 100%;
			}
		}
	}
	.tomtom-cart-side-photo-text {
		display: flex;
		flex-direction: column;
		padding-left: 20px;
		text-align: left;
		width: 90%;
		@media screen and (min-width: map-get(vars.$breakpoints, "xm")) {
			width: 60%;
		}

		h3 {
			padding-left: 20px;
			font-weight: bold;
			font-size: 28px;
			line-height: 24px;
			border-left: 3px solid black;
		}
		
		.tomtom-cart-side-photo-text-description {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			padding-top: 20px;
			color: colors.$black;
			> p{
				padding: 10px;
				letter-spacing: 2px;
				@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
					padding: 10px 80px 20px 30px;
					letter-spacing: 2px;
				}
			}

			ul {
				display: grid;
				grid-template-columns: 1fr ;
				width: 100%;
				padding-top: 0;
				@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
					padding-top: 20px;
					grid-template-columns: 1fr 1fr;
				}
			
				li {
					list-style: none;
					padding: 8px 0;
					p {
						font-size: 12px;
						font-weight: 700;
						text-transform: uppercase;
						@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
							font-size: 16px;
						}
						i {
							margin-right: 20px;
							font-size: 12px;
							color: colors.$primary-100;
						}
						span {
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}
