@use "src/styles/colors";
@use "src/styles/vars";

.services-list-wrapper {
	position: relative;
	width: 100%;
	height: 60vh;
	max-height: 400px;
	display: flex;
	flex-direction: row !important;
	justify-content: flex-end;
	z-index: 1;
	.services-list-section {
		position: absolute;
		top: 0;
		left: 0;
		width: 40%;
		height: 100%;
		display: flex;
		align-items: center;
		z-index: 100;

		ul {
			height: 80%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: space-around;

			li {
				width: 90%;
				list-style: none;

				color: colors.$light-200;
				a {
					display: inline-block;
					width: 100%;
					padding: 20px 0;
					text-decoration: none;
					font-size: 12px;
					color: colors.$light-200;
					border-bottom: 1px solid colors.$light-200;
					transition: all 0.3s;

					&:hover {
						color: colors.$light;
						border-bottom: 1px solid colors.$light;
					}
					@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
						font-size: 14px;
					}
					@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
						font-size: 16px;
					}
				}
			}
		}
	}
	.services-list-img-section {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60%;
		height: 100%;
		i {
			font-size: 100px;
			@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
				font-size: 200px;
			}
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				font-size: 300px;
			}
		}
	}

	.services-list-hero {
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			90deg,
			rgba(54, 54, 56, 0.9990371148459384) 0%,
			rgba(54, 54, 56, 1) 39%,
			rgba(164, 171, 172, 1) 100%
		);
		opacity: 0.9;
		z-index: 0;
	}
}
