.event-service {
	article {
		padding: 20px 40px;
	}
	.event-service-ul {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
