@use "src/styles/colors";
@use "src/styles/vars";
.routes-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow: hidden;
	.routes-section-design-description {
		padding: 20px 16px;
		line-height: 20px;
		text-align: justify;
		font-size: 14px;
		@media screen and (min-width: map-get(vars.$breakpoints, "small")) {
			padding: 40px 40px;
			line-height: 24px;
			font-size: 16px;
		}
	}
	.routes-section-ul {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.routes-hero-img {
		position: relative;
		width: 100%;
		height: 200px;
		display: flex;
		background: linear-gradient(
			132deg,
			rgba(2, 0, 36, 0.9122023809523809) 0%,
			rgba(61, 61, 64, 1) 35%,
			rgba(81, 87, 88, 1) 74%,
			rgba(37, 37, 37, 0.9346113445378151) 100%
		);
		overflow: hidden;
		div {
			// transform: skew(-30deg);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.pl-img {
			width: 70rem;
			height: 100%;
			background-image: url("../../images/headerPicture/warszawa.jpg");
		}
		.dk-img {
			width: 70rem;
			height: 100%;
			background-image: url("../../images/headerPicture/kp.jpg");
		}
	}

	.route-section-description {
		margin: 40px 0;
		display: grid;
		grid-template-columns: 60% 40%;
		grid-template-rows: 50vh 40vh;
		width: 100%;
		.one {
			padding: 40px 60px;

			h1 {
				line-height: 25px;
				padding: 15px 30px;
				border-left: 4px solid black;
				font-size: 50px;
				letter-spacing: 2px;
			}
			p {
				text-align: left;
				padding: 40px 0;
			}
		}
		.two {
			background-image: url("../../images/headerPicture/kp.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.three {
			background-image: url("../../images/headerPicture/warszawa.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
		.four {
			background: rgb(195, 184, 30);
		}
	}
	
	
}


