@use '../../styles/colors';
.tomtom-cart-top {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 280px;
	border-radius: 5px;
	overflow: hidden;
	border: 1px solid colors.$light-100;
	box-shadow: 0 0 10px colors.$light-100;
	

	.tomtom-cart-top-img {
		width: 100%;
		height: 180px;
		// border: 1px solid colors.$white;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.tomtom-cart-top-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
		background: colors.$white;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		
		

		h2 {
			font-size: 20px;
			padding: 10px 0;
		}
		p {
			font-size: 16px;

			padding: 10px 0;
		}
		button {
			margin: 20px 0;
		}
	}
}
