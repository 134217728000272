@use "../../styles/colors";
@use "../../styles/vars";
.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	
	color: colors.$black;
	h1 {
		margin: 40px 2px 0;
		padding: 20px 40px;
		text-align: center;
		border-radius: 5px;
		font-size: 34px;
		letter-spacing: 2px;
		color: colors.$black;
	}

	p {
		padding: 20px;
	}

	.services {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		ul {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			justify-items: center;
			margin: 40px 0;
			padding: 20px 0;
			width: 100%;
			text-align: center;
		}
	}
	section.about-us {
		position: relative;
		align-items: center;
		justify-content: center;
		z-index: 0;
		
	}
	.why-us {
		width: 100%;
		ul {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
		
			padding: 80px 0;
			@media screen and (min-width: map-get(vars.$breakpoints, "medium")) {
				flex-direction: row;
				justify-content: space-around;
			}
		}
	}

	.our-cars {
		ul {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 20px 0;
			
		}
	}
}
.fb-page {
	width: 100%;
	height: 500px;
}